import React, { Component, useState } from "react"
import PageHelmet from "../component/common/Helmet"
import ModalVideo from "react-modal-video"
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi"
import { Link, graphql } from "gatsby"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import HeaderTwo from "../component/header/HeaderTwo"
import Footer from "../component/footer/Footer"
import ContactThree from "./contact/ContactThree"
import "./../components/layout.css"
import SEO from "../components/seo"

const useVideo = () => {
    const [isOpen, openModal] = useState(false)

    return { isOpen, openModal }
}

const InlineVideo = ({ node }) => {
  
const { isOpen, openModal } = useVideo()

  const url = (new URL(node.data.uri)).searchParams

  const videoId = url.get('v')

  return (
    <div className="video-wrapper position-relative mb--40">
      <div className="thumbnail">
        <img
          src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
          alt={node.content.value}
        />
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => {
          openModal(false)
        }}
      />
      <button
        className="video-popup position-top-center"
        onClick={() => {
          openModal(true)
        }}
      >
        <span className="play-icon"></span>
      </button>
    </div>
  )
}

const journalOptions = {
  renderMark: {
    [MARKS.ITALIC]: text => (
      <>
        <span>{text}</span>
      </>
    ),
  },

  renderNode: {
    [INLINES.HYPERLINK]: node => {
      if (node.data.uri.indexOf("youtube.com") !== -1) {
        return <InlineVideo node={node} />
      } else {
        return <a href={node.data.url}>{node.content.value}</a>
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-style">{children}</ul>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <blockquote className="rn-blog-quote">{children}</blockquote>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => {
      
      const { title, description, file } = node.data.target.fields

      return (
        <div className="thumbnail">
          <img className="w-100" src={file["en-US"].url} alt={title["en-US"]} />
          <span>{description["en-US"]}</span>
        </div>
      )

    return <p>Embedded Assets Fucked up </p>

    },
  },
}

class BlogDetails extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    const {
      journal,
      mainImage,
      title,
      subtitle,
      url,
      createdAt,
      description
    } = this.props.data.contentfulJournalEntry

    const socialMedia = this.props.data.allContentfulSocialMediaAccount.nodes

    return (
      <React.Fragment>
        <PageHelmet pageTitle={title} />
        <SEO
          title={title}
          description={description ? description.description : title}
          image={mainImage.fluid.src || ""}
          lang={"english"}
        />
        <HeaderTwo socialMedia={socialMedia} />
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2
                    className="title theme-gradient"
                    style={{ textTransform: "capitalize" }}
                  >
                    {title}
                  </h2>
                  <h3 style={{ color: "white" }}>{subtitle}</h3>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      {createdAt}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {mainImage ? (
          <div className="slider-wrapper">
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
              style={{
                backgroundImage: `url("${mainImage.fluid.src}")`,
                backgroundSize: "cover",
                backgroundPosition: "5% 50%",
                height: "700px",
              }}
            />
          </div>
        ) : (
          ""
        )}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    {documentToReactComponents(journal.json, journalOptions)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        <div className="blog-comment-form pb--120 bg_color--1">
          <ContactThree />
        </div>
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer socialMedia={socialMedia} />
      </React.Fragment>
    )
  }
}

export const journalQuery = graphql`
         query JournalQuery($slug: String!) {
           allContentfulSocialMediaAccount {
             nodes {
               url
               socialSite
             }
           }
           contentfulJournalEntry(url: { eq: $slug }) {
             description {
               description
             }
             journal {
               json
             }
             createdAt(formatString: "MM/DD/YYYY")
             mainImage {
               fluid {
                 src
               }
             }
             subtitle
             title
             url
             categories
             categoryGroup {
               url
               category
             }
           }
         }
       `
export default BlogDetails
